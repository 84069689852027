<!-- 更改手机号页面 -->
<template>
  <div class="do-page-updataphone">
    <base-nav-bar @click-left="router.go(-1)" left-arrow title="更改手机号">
    </base-nav-bar>
    <base-bscroll :safeareaNavbar="true" :options="{ bounce: true }">
      <div class="do-page-updataphone-content">
        <img
          class="iphone-icon"
          :src="require('@/assets/images/my/iphone-icon.png')"
        />
        <div class="input-wrap">
          <div class="title">+86</div>
          <input v-model="phone" placeholder="请输入你的手机号码" />
        </div>
        <van-button
          :loading="loading"
          loading-type="spinner"
          @click="toAuthcode"
          :class="`code-btn ${checkPhone(phone) ? 'code-btn-curr' : ''}`"
        >
          获取验证码
        </van-button>
      </div>
    </base-bscroll>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { ref } from "vue";
import { Toast } from "vant";
import { getAccountCaptcha } from "@/axios/user";
const loading = ref(false);
const router = useRouter();
const phone = ref("");

async function toAuthcode() {
  // 验证手机号
  if (checkPhone(phone.value)) {
    loading.value = true;
    try {
      await getAccountCaptcha({ phone: phone.value });
      loading.value = false;
      router.push("/auth-code/" + phone.value + "-updataPhone");
      return false;
    } catch (error) {
      if (error) {
        Toast({
          position: "bottom",
          message: error.msg || "发送验证码，操作过于频繁",
        });
      }
    }
    setTimeout(() => {
      loading.value = false;
    }, 300);
  }
}
//手机号码正则表达式验证
function checkPhone(phone) {
  if (!/^1[3456789]\d{9}$/.test(phone)) {
    return false;
  }
  return true;
}
console.log("router", router);
</script>

<style lang="scss">
.do-page-updataphone {
  .do-com-navbar {
    &::after {
      border-bottom-width: 0px !important;
    }
  }
  .do-com-scroll-wrapper {
    background: #ffffff;
  }
  &-content {
    padding: 0.42rem 0.24rem 0 0.24rem;
    display: flex;
    align-items: center;
    flex-flow: column;
    .code-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.96rem;
      height: 0.84rem;
      background: rgba(3, 5, 15, 0.25);
      border-radius: 0.16rem;
      font-size: 0.3rem;
      font-weight: 500;
      color: #ffffff;
      margin-top: 0.4rem;
      transition: all 0.3s;
    }
    .code-btn-curr {
      background: #0057ff;
      transition: all 0.3s;
    }
    .input-wrap {
      padding: 0.28rem 0.24rem;
      background: rgba(2, 4, 15, 0.04);
      border-radius: 0.16rem;
      height: 0.96rem;
      display: flex;
      align-items: center;
      width: 100%;
      .title {
        font-size: 0.28rem;
        font-weight: 400;
        color: #03050f;
        @include border-right(1px, rgba(3, 5, 15, 0.25));
        padding-right: 0.24rem;
      }
      input {
        margin-left: 0.24rem;
        font-size: 0.28rem;
        font-weight: 400;
        color: rgba(3, 5, 15, 0.85);
        &::-webkit-input-placeholder {
          font-size: 0.28rem;
          font-weight: 400;
          color: rgba(3, 5, 15, 0.25);
        }
      }
    }
    .iphone-icon {
      width: 1.6rem;
      height: 1.6rem;
      margin-bottom: 0.4rem;
    }
  }
  .right-text {
    font-size: 0.28rem;
    font-weight: 400;
    color: rgba(3, 5, 15, 0.45);
    transition: all 0.3s;
  }
}
</style>
